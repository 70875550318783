import React from "react";
import { Link } from "gatsby";
import logoImg from "../../assets/image/png/logo.png";
import whiteLogoImg from "../../assets/image/png/white-logo.png";


const Logo = ({ color = "front", height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      <img src={color === 'white' ? whiteLogoImg : logoImg}></img>
    </Link>
  );
};

export default Logo;
