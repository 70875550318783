import React, { useRef, useState } from "react";

import { Form, Alert, Spinner } from "react-bootstrap";
import emailjs from '@emailjs/browser';

import { Input, TextArea, Button } from "../Core";

const ContactForm = ({ theme = "dark", ...rest }) => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    setShowWaiting(true);
    emailjs.sendForm('service_bi8qtu8', 'template_06ixy9m', form.current, 'XWe3RqWXlS3Fww-Zl')
        .then((result) => {
          setShowError(false);
          setShowWaiting(false);
          setShowSuccess(true);
        }, (error) => {
          setShowError(true);
          setShowWaiting(false);
          setShowSuccess(false);
        });
  };

  const [showSuccess, setShowSuccess] = useState(false);
  const [showWaiting, setShowWaiting] = useState(false);
  const [showError, setShowError] = useState(false);

  if (showWaiting) {
    return (
        <Spinner animation="border" role="status"></Spinner>
    );
  }

  if (showError) {
    return (
        <Alert variant="danger" onClose={() => setShowSuccess(false)} dismissible>
          <p>Возникла ошибка при отправке сообщения. Попробуйте позже</p>
        </Alert>
    );
  }

  if (showSuccess) {
    return (
        <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
          <p>Сообщение успешно отправлено! Ожидайте ответ</p>
        </Alert>
    );
  }

  return (
    <Form
      ref={form} onSubmit={sendEmail}
      name="contact"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      {...rest}
    >
      {/* You still need to add the hidden input with the form name to your JSX form */}
      <input type="hidden" name="form-name" value="contact" />
      <div className="mt-4">
        <Input type="text" placeholder="Имя" required name="name" />
      </div>
      <div className="mt-4">
        <Input type="text" placeholder="Телефон" required name="phone"/>
      </div>
      <div className="mt-4">
        <Input type="text" placeholder="Тема" required name="subject" />
      </div>
      <div className="mt-4 ">
        <TextArea
          rows={4}
          placeholder="Сообщение"
          required
          name="message"
        />
      </div>
      <div className="mt-4 mt-lg-5">
        <Button arrowRight variant="primary" type="submit">
          Отправить
        </Button>
      </div>
    </Form>
  );
};

export default ContactForm;
