// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-1-js": () => import("./../../../src/pages/work/1.js" /* webpackChunkName: "component---src-pages-work-1-js" */),
  "component---src-pages-work-2-js": () => import("./../../../src/pages/work/2.js" /* webpackChunkName: "component---src-pages-work-2-js" */),
  "component---src-pages-work-3-js": () => import("./../../../src/pages/work/3.js" /* webpackChunkName: "component---src-pages-work-3-js" */),
  "component---src-pages-work-4-js": () => import("./../../../src/pages/work/4.js" /* webpackChunkName: "component---src-pages-work-4-js" */),
  "component---src-pages-work-5-js": () => import("./../../../src/pages/work/5.js" /* webpackChunkName: "component---src-pages-work-5-js" */),
  "component---src-pages-work-6-js": () => import("./../../../src/pages/work/6.js" /* webpackChunkName: "component---src-pages-work-6-js" */),
  "component---src-pages-work-7-js": () => import("./../../../src/pages/work/7.js" /* webpackChunkName: "component---src-pages-work-7-js" */),
  "component---src-pages-work-8-js": () => import("./../../../src/pages/work/8.js" /* webpackChunkName: "component---src-pages-work-8-js" */),
  "component---src-pages-work-9-js": () => import("./../../../src/pages/work/9.js" /* webpackChunkName: "component---src-pages-work-9-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

