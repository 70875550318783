import React from "react";
import { Container } from "react-bootstrap";
import { Box, Title } from "../Core";

const Footer = ({ isDark = true }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box
        bg="bg"
        pt={[0, null, null, "3.75rem"]}
        pb="3.75rem"
        css={`
          z-index: -50;
        `}
        className="position-relative"
      >
        <Container>

        </Container>
      </Box>
    </>
  );
};

export default Footer;
